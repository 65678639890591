.App-wrapper {
  width: 1600px;
/*  overflow:hidden;*/
  margin: auto;
}

.App {
  text-align: left;
  width: 1440px;
  margin: auto;
  margin-bottom: 100px;
  margin-top: 20px;
}

.Header {
    display: flex;
}

.fill-page {
  height: 700px;
}

/*.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}*/




