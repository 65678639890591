.bkgrd {
    fill: rgb(235,235,235);
    fill-opacity: 0.2;
    stroke-width: 2;
    stroke: rgb(235,235,235);
}

.grid, .axis {
    opacity: 0;
}


.axis .tick{stroke-width: 2;shape-rendering: crispEdges;}
.axis .domain{stroke-width: 0;stroke:rgb(200,200,200); shape-rendering: crispEdges;}
.grid .domain{stroke-width: 0;stroke:rgb(255,225,155); shape-rendering: crispEdges;}

.grid line{ stroke-width: 1;stroke:rgb(255,255,255);shape-rendering: crispEdges;}




.data-path {
    fill: none;
    stroke-width: 3;
    opacity: 1.0;
/*    -webkit-transition: stroke-width 50ms;
    transition: stroke-width 50ms;*/

}

.data-path.small {
    stroke-width: 1;
    opacity: 0.0;
}


.graph-clicked {
    box-sizing: border-box;
    box-shadow: 0px 0px 3px 3px rgb(120,170,220);
}

.graph-wrapper{
    pointer-events: all;
    display: inline;
    z-index: -1;
}

.graph {
    position:relative;
    z-index: 1;
}

.graph.ontop{
    z-index: 1;
}
