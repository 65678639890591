.detail {
    position: relative;
  height:670px;
  box-shadow: 0px 0px 5px 0px grey;
}

.detailGraph {
    position: absolute;
    left: 10px;
    top: 0px;
    pointer-events: none;
}

div.tooltipError {
  position: absolute;
  text-align: center;
  width: 200px;
  height: 28px;
  padding: 2px;
  font: 10px sans-serif;
  background: lightgrey;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
}

#aux-plot-DQ-sideBar {
    pointer-events: all;
}


.detailGraph .bkgrd {
    fill: rgb(235,235,235);
    fill-opacity:1;
}

.detailGraph .axis,.grid{
    opacity:1;
}


.bkgrd-aux {
    opacity: 0.3;
}

.q-0 {
    opacity: 1;
}
.q-1 {
    opacity: 0.7;
}
.q-2 {
    opacity: 0.3;
}


.axis .tick{stroke-width: 2;shape-rendering: crispEdges;}
.axis .domain{stroke-width: 0;stroke:rgb(200,200,200); shape-rendering: crispEdges;}
.grid .domain{stroke-width: 0;stroke:rgb(255,225,155); shape-rendering: crispEdges;}

.grid line{ stroke-width: 1;stroke:rgb(255,255,255);shape-rendering: crispEdges;}


.overlay {
    fill: none;
    stroke: none;
    pointer-events: all;
}

.focusLine {
    fill: none;
    stroke: steelblue;
    stroke-width: 0.5px;
}

.focusCircle {
    fill: None;
	stroke: red;
    stroke-width: 2;
}

.focus-rect {
    fill: none;
    stroke: red;
    stroke-width: 1;
}

.unit-text {
    align:left;
}


/*Value Box*/

#value-box {
    font-size: 12px;
}

#value-box tbody tr {
    height: 20px;
}

#value-box thead tr {
    height: 30px;
    vertical-align: top;
    font-weight: bold;
}


.data-path-detail {
    fill: none;
    stroke-width: 2;
    opacity: 0.7;
}
