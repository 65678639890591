.Menu {
    flex: 1;
    display:flex;
    margin-bottom: 20px;
}

.Menu h1 {
  margin-top: 0px;
  font-size: 16px;
}

/*.menu-sticky {
  position: sticky;
  display: flex;
  top:0%;
  left:50%;
  width: 1600px;
  transform: translateX(-45%);
  z-index: 9999;
  background-color: white;
}*/

.menu-sticky {
  position: sticky;
  display: flex;
  margin-bottom: 20px;
  padding-top: 30px;
  top:0%;
  z-index: 9999;
  background-color: white;
}

.gas-row {
  display: flex;
  justify-content: space-around;
  width: 1050px;
  left: 0;
}

.menu-station-block {
  width: 900px;
  min-height: 320px;
  margin-left: 20px;
  margin-right: 20px;
}

.menu-station-list {
    -webkit-column-count: 5; /* Chrome, Safari, Opera */
    -moz-column-count: 5; /* Firefox */
    column-count: 5;
}

.menu-station-item {
  cursor: pointer;
  opacity: 0.2;
}

.menu-station-item.selected-station{
  opacity:1;
}

.menu-station-item * {
  pointer-events: none;
}


.menu-station-item p{
  margin: 0px 4px 4px 0px;
  font-size: 14px;
}

.gas-block {
  flex:1;
}
/* "x-axes are local time" */
.menu-axes-info {
  position: relative;
  font-weight: bold;
  font-size: 10px;
  left: 120px;
}

.gas-proto {
  fill: lightgrey;
  fill-opacity: 0.5;
}

.prod-label {
  font-size: 14px;
  margin: 0px 0px 5px 0px;
}

.gas-svg.selected-gas {
  opacity: 1;
}
.gas-svg {
  opacity: 0.2;
}

.gas-label {
  text-anchor: middle;
  font-size: 14px;
}

.proto-label {
  font-size: 10px;
}
.proto-label.limit0 {
  text-anchor: end;
  transform: translate(-2px,0px);
}
.proto-label.limit1 {
  text-anchor: end;
  transform: translate(-2px,6px);
}
.proto-label.time0 {
  text-anchor: start;
  transform: translate(1px,10px);
}
.proto-label.time1 {
  text-anchor: end;
  transform: translate(-1px,10px);
}



.gas-headings * {
  position:absolute;
  top:7px;
}

.gas-headings .tot{
  left: 57px;
}

.gas-headings .trop{
  left: 506px;
}

.gas-headings .surfc{
  left: 801px;
}

