.detail-day-select {
    position: relative;
    top: 20px;
    left:350px;
}

.menu-day-select {
/*    position: fixed;
    top: 20px;
    right:50px;*/
    display: inline-flex;
    padding-left: 20px;
    padding-right: 20px;
    z-index: 999;
    background-color: white;
    width: 220px;
}

.menu-day-select p {
  position: absolute;
  font-size: 10px;
  top: 72%;
  right: 243px;
}

.menu-day-dropdown {
  flex: 1;
  width: 150px;
  height: 30px;
}

.day-arrow-left {
  display: inline-block;
  margin: auto;
  width: 30px;
  height: 20px;
  cursor:pointer;
}
.day-arrow-left.disabled{cursor:default;}

.day-arrow-left:after {
  content: '';
display: inline-block;
  margin-top: 0px;
  margin-left: 10px;
  width: 10px;
  height: 10px;
  border-top: 5px solid #0A73F7;
  border-right: 5px solid #0A73F7;
  -moz-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.disabled.day-arrow-left:after {
  border-top: 5px solid rgb(210,210,220);
  border-right: 5px solid rgb(210,210,220);
}

.day-arrow-right {
  margin: auto;
  display: inline-block;
  width: 30px;
  height: 20px;
  cursor:pointer;
}
.day-arrow-right.disabled{cursor:default;}

.day-arrow-right:after {
  content: '';
display: inline-block;
  margin-top: 0px;
  margin-left: 2px;
  width: 10px;
  height: 10px;
  border-top: 5px solid #0A73F7;
  border-right: 5px solid #0A73F7;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.disabled.day-arrow-right:after {
  border-top: 5px solid rgb(210,210,220);
  border-right: 5px solid rgb(210,210,220);
} 
