
.row {
position: relative;
  width: inherit;
  padding-top: 10px;
  padding-bottom: 10px;
  /*box-shadow: 0px 0px 5px 0px grey;*/
}

.row span {
  position: absolute;
  left: 50px;
  display:inline-block;
}

.station-label{
    position: absolute;
    right:100%;
    top: 10%;
    padding: 5px 5px 5px 5px;
    height: inherit;
    width: 200px;
    text-align: right;
}

.station-label p {
      display: inline;
}

.row-day-select {
    float:right;
}

.day-bar {
  position: absolute;
  top: 30%;
  right: 240px;

}


.download-button {
    position: absolute;
    top: 28%;
    right: 150px;
  display: inline-block;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  cursor: pointer;
  padding: 5px 5px;
  margin-right: 5px;
  border: 1px solid lightgrey;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  font: normal 12px/normal Arial, Helvetica, sans-serif;
  text-decoration: none;
  color: #FFFFFF;
  -o-text-overflow: clip;
  text-overflow: clip;
  background: lightgrey;
  -webkit-box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.2) ;
  box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.2) ;
  -webkit-transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
  -moz-transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
  -o-transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
  transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
}

/*.download-button:hover {
  background: #7E84C7;
  -webkit-transition: color 300ms cubic-bezier(0.42, 0, 0.58, 1);
  -moz-transition: color 300ms cubic-bezier(0.42, 0, 0.58, 1);
  -o-transition: color 300ms cubic-bezier(0.42, 0, 0.58, 1);
  transition: color 300ms cubic-bezier(0.42, 0, 0.58, 1);
}*/
